<template>
  <b-modal
  hide-header-close
  v-model="showModal"
  @ok="finish('base64')"
  size="lg"
  @cancel="cancelEdit">
    <vue-cropper
      class="image-cropper"
      ref="cropper"
      :img="img||modelSrc"
      :output-size="size"
      :output-type="outputType"
      :info="true"
      :full="full"
      :fixed="fixed"
      :fixed-number="fixedNumber"
      :can-move="canMove"
      :can-move-box="canMoveBox"
      :fixed-box="fixedBox"
      :original="original"
      :auto-crop="autoCrop"
      :auto-crop-width="autoCropWidth"
      :auto-crop-height="autoCropHeight"
      :center-box="centerBox"
      @real-time="realTime"
      :high="high"
      :mode="mode"
      :max-img-size="max"
    ></vue-cropper>
    <b-row class="justify-content-center">
      <label class="btn" for="uploads" title="Upload"
        ><i class="fa fa-upload" aria-hidden="true"></i
      ></label>
      <input
        type="file"
        id="uploads"
        style="position: absolute; clip: rect(0 0 0 0);"
        accept="image/png, image/jpeg, image/gif, image/jpg"
        @change="uploadImg($event)"
      />

      <b-button
        @click="startCrop"
        v-if="!crap"
        class="btn"
        variant="light"
        title=" Start Crop"
        ><i class="fa fa-crop" aria-hidden="true"></i
      ></b-button>
      <b-button
        @click="stopCrop"
        v-else
        class="btn"
        variant="light"
        title="Stop Crop"
        ><i class="fa fa-stop" aria-hidden="true"></i
      ></b-button>
      <b-button @click="clearCrop" class="btn" variant="light" title="Clear"
        ><i class="fa fa-ban" aria-hidden="true"></i
      ></b-button>
      <b-button @click="refreshCrop" class="btn" variant="light" title="Refresh"
        ><i class="fa fa-refresh" aria-hidden="true"></i
      ></b-button>
    </b-row>
    <b-row class="justify-content-center">
      <b-button
        @click="changeScale(5)"
        class="btn"
        variant="light"
        title="Zoom In"
        ><i class="fa fa-search-plus" aria-hidden="true"></i
      ></b-button>
      <b-button
        @click="changeScale(-5)"
        class="btn"
        variant="light"
        title="Zoom Out"
        ><i class="fa fa-search-minus" aria-hidden="true"></i
      ></b-button>
      <b-button
        @click="rotateLeft"
        class="btn"
        variant="light"
        title="Rotate Left"
        ><i class="fa fa-undo" aria-hidden="true"></i
      ></b-button>
      <b-button
        @click="rotateRight"
        class="btn"
        variant="light"
        title="Rotate Right"
        ><i class="fa fa-repeat" aria-hidden="true"></i
      ></b-button>
    </b-row>
  </b-modal>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 1,
    },
    full: {
      type: Boolean,
      default: true,
    },
    outputType: {
      type: String,
      default: 'png',
    },
    canMove: {
      type: Boolean,
      default: true,
    },
    fixedBox: {
      type: Boolean,
      default: false,
    },
    original: {
      type: Boolean,
      default: false,
    },
    canMoveBox: {
      type: Boolean,
      default: true,
    },
    autoCrop: {
      type: Boolean,
      default: true,
    },
    autoCropWidth: {
      type: Number,
      default: 160,
    },
    autoCropHeight: {
      type: Number,
      default: 160,
    },
    centerBox: {
      type: Boolean,
      default: true,
    },
    high: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: 1000,
    },
    mode: {
      type: String,
      default: 'contain',
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    fixedNumber: {
      type: Array,
      default: () => [1, 1],
    },


  },
  data() {
    return {
      showModal: false,
      model: false,
      modelSrc: '',
      crap: false,
      previews: {},
      show: true,

    };
  },
  watch: {
    isOpen() {
      this.showModal = this.isOpen;
    },
  },
  methods: {
    startCrop() {
      // start
      this.crap = true;
      this.$refs.cropper.startCrop();
    },
    stopCrop() {
      //  stop
      this.crap = false;
      this.$refs.cropper.stopCrop();
    },
    clearCrop() {
      // clear
      this.$refs.cropper.clearCrop();
    },
    refreshCrop() {
      // clear
      this.$refs.cropper.refresh();
    },
    changeScale(num) {
      const n = num || 1;
      this.$refs.cropper.changeScale(n);
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    cancelEdit() {
      this.$emit('cancelEdit');
    },
    finish(type) {
      if (type === 'blob') {
        this.$refs.cropper.getCropBlob((data) => {
          const img = window.URL.createObjectURL(data);
          this.model = true;
          this.modelSrc = img;
          this.$emit('saveImage', this.modelSrc);
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.model = true;
          this.modelSrc = data;
          this.$emit('saveImage', this.modelSrc);
        });
      }
      this.$emit('cancelEdit');
    },
    realTime(data) {
      this.previews = data;
    },
    uploadImg(ev) {
      const file = ev.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(ev.target.value)) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.modelSrc = data;
      };
      reader.readAsArrayBuffer(file);
      return true;
    },
  },
};
</script>
<style scoped>
.image-cropper{
  height: 55vh;
}
</style>
