<template>
  <div>
    <div
      class="main-img my-8"
      :style="{ backgroundImage: 'url(' + imageData + ')' }"
    >
      <div
        class="change-img"
        v-if="imageData"
      >
        <button
          class="change-btn"
          placeholder="Select Image"
          @click="pickFile"
        >
          <i class="fas fa-pen" style="color:#DDDDDD;"></i>
        </button>
      </div>
      <figure class="figure-one">
        <b-button
          class="ImgBtn"
          variant="info"
          v-if="!imageData"
          placeholder="Select Image"
          @click="pickFile"
        >Upload photo</b-button>
      </figure>
      <div>
        <input
          ref="image"
          type="file"
          style="display: none"
          accept="image/*"
          @change="onFilePicked"
        >
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'UploadImg',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      model: '',
      imageData: '',
      imageName: '',
      file: {},
    };
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },
    onFilePicked(e) {
      // Get file
      const { files } = e.target;
      // If file is available
      if (files[0] !== undefined) {
        // Convert file into base64
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.imageData = fr.result;
        });
      }
    },
    removeImage(e) {
      if (e) e.preventDefault();
      this.imageData = '';
      this.model = '';
      this.$refs.image.value = null;
    },
  },
  watch: {
    imageData(val) {
      if (val) this.model = this.imageData;
    },
    model(val) {
      if (!val) this.removeImage();
      this.$emit('input', val);
    },
    value: {
      immediate: true,
      handler(val) {
        this.imageData = val;
        this.model = val;
      },
      deep: true,
    },
  },
};
</script>


<style scoped>
.main-img {
  width: 166px;
  height: 166px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #ebebeb;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.change-btn {
  background: #BD9D5F;
  border: none;
  margin: 5px;
}
.remove-btn:hover {
  color: #BD9D5F;
}
.change-img {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.ImgBtn {
  background-color: #BD9D5F;
  text-align: center;
  color: white;
  line-height: 18px;
}
</style>
