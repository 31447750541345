<template>
  <div class="user-settings-container mt-1">
    <div class="userSettingsForm">
      <b-row>
        <b-col
          lg="4"
          md="6"
          sm="12"
          class="profilePictureSection mb-3 text-center"
        >
          <div class="profilePicture">
            <img :src="profilePicture" alt="avatar" class="user-avatar" />
            <i
              class="fa fa-pen-square logoIcon"
              title="Edit"
              @click="openCropper"
            ></i>
            <i class="fa fa-question"  v-b-popover.hover.top='"Upload images in square orientation"'></i>

          </div>
        </b-col>
        <b-col lg="4" md="6" sm="12" class="mb-2 user-settings-body">
          <b-row class="mb-3">
            <b-col>
              <label class="inputs-label">Name</label>
              <b-form-input
                v-validate="'required|alpha_spaces|min:2'"
                name="Name"
                class="user-setting-input"
                size="sm"
                v-model="form.user.first_name"
              ></b-form-input>
              <span class="error" v-if="errors.has('Name')">{{
                errors.first("Name")
              }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-0">
            <b-col>
              <div class="settings-tel-input">
                <label class="inputs-label">Mobile Number</label>
                <vue-tel-input
                  style="color: #525252"
                  id="inline-form-input-phone-number"
                  @onInput="onInput"
                  v-validate="'required'"
                  v-model="form.phone_number"
                  class="reg-inp"
                  placeholder="Telephone Number*"
                  name="phone"
                  :preferredCountries="['us', 'gb', 'de', 'ch']"
                  :class="{ 'is-danger': errors.has('phone') }"
                  :enabledCountryCode="true"
                  :disabled-fetching-country="true"
                ></vue-tel-input>
                <span
                  v-if="errors.first('phone') || isValidPhone"
                  class="error"
                >
                  {{
                    errors.first("phone")
                      ? errors.first("phone")
                      : "Please enter a valid mobile number"
                  }}
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <label class="inputs-label">Industry Tags</label>
              <multiselect
                ref="multiselect"
                v-validate
                data-vv-rules="required"
                data-vv-name="tag"
                data-vv-value-path="tag"
                class="tagsMultiselect"
                v-model="addTags"
                :options="tags"
                track-by="id"
                label="name"
                :searchable="false"
                :close-on-select="false"
                :show-labels="true"
                :multiple="true"
                :maxHeight="150"
                :clear-on-select="false"
                open-direction="bottom"
                placeholder="select one or more industry tags"
                :allow-empty="true"
                @open="fetchTags"
              >
              </multiselect>
              <span class="error" v-if="errors.has('tag')">{{
                errors.first("tag")
              }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <label class="inputs-label">Biography (max 100 words)</label>
              <textarea
                v-model="form.biography"
                name="biography"
                id="Personal"
                placeholder="Personal Biography (max 100 words)"
                v-validate="{ required: true }"
              >
              </textarea>
              <span class="error" v-if="errors.has('biography')">{{
                errors.first("biography")
              }}</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="4" md="12" class="mb-2 user-settings-body">
          <b-row class="mb-2">
            <b-col>
              <label class="inputs-label">Surname</label>
              <b-form-input
                 v-validate="'required|alpha_spaces'"
                name="Surname"
                class="user-setting-input"
                size="sm"
                placeholder="Enter your surname"
                v-model="form.user.last_name"
              ></b-form-input>
              <span class="error" v-if="errors.has('Surname')">{{
                errors.first("Surname")
              }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <label class="inputs-label">Corporate Email</label>
              <b-form-input
                name="email"
                autocorrect="off"
                autocapitalize="off"
                autocomplete="off"
                v-validate="'required|email'"
                class="user-setting-input"
                size="sm"
                v-model="email"
                style="text-transform: unset"
                :class="{ 'is-danger': errors.has('email') }"
              ></b-form-input>
              <span class="error" v-if="errors.has('email')">{{
                errors.first("email")
              }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <label class="inputs-label">Job Title</label>
              <b-form-input
                v-validate="{ required: true }"
                class="user-setting-input"
                name="job title"
                id="input-small"
                size="sm"
                v-model="form.job_title"
                :class="{ 'is-danger': errors.has('job title') }"
              ></b-form-input>
              <span class="error" v-if="errors.has('job title')">{{
                errors.first("job title")
              }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="companyInfoBox">
              <span class="inputs-label">Company</span>
              <div class="companyImgBox">
                <img v-if="companyImg" :src="companyImg" alt="" />
                <div>
                  <p>You don’t work anymore for {{ companyName }}?</p>
                  <button @click="openChangeCompanyModal">
                    Change Company
                  </button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="sm-5 justify-content-md-center">
        <b-col xl="4" lg="0" />
        <b-col xl="8" lg="12" class="p-3 mb-3 user-settings-body">
          <div class="text-center p-3">
            <a
              class="user-settings-support-text support-text"
              href="mailto:info@carriercommunity.com?subject=Support&cc=Kara@carriercommunity.com;w.schmidt@carriercommunity.com"
            >
              Need support? Click here!
            </a>
          </div>
          <b-button
            @click="showConfirmSaveModal"
            class="user-setting-button"
            block
            variant="info"
            >SAVE SETTINGS
          </b-button>
        </b-col>
      </b-row>
    </div>
    <ImageCropper
      @saveImage="savePhoto"
      :isOpen="showCropper"
      @cancelEdit="cancelEditing"
      :fixedNumber="[1, 1]"
      :centerBox="false"
    />
    <b-modal
      v-model="isChangeCompanyModal"
      hide-footer
      title="CHANGE COMPANY"
      size="m"
      style="text-align: center"
    >
      <b-container>
        <div class="changeCompanyModal">
          <p>Please select another company to be associate with as employee.</p>
          <div>
            <b-form-input
              v-model="searchCompany"
              placeholder="Search by name"
              debounce="400"
            />
          </div>
          <section v-if="companies.length">
            <div
              class="companies"
              v-for="company in companies"
              :key="company.id"
              :class="{ active: isSelectedCompany === company.id }"
            >
              <div @click="setCompanyUrl(company)">
                <img
                  :src="
                    company.logo
                      ? company.logo
                      : './../../assets/default-user-profile-image.png'
                  "
                  alt=""
                />
                <p>{{ company.name }}</p>
              </div>
            </div>
          </section>
          <div>
            <p>Please select your position in the new company.</p>
            <b-form-input v-model="newJobTitle" placeholder="Job Title" />
            <br />
            <p>Please select your email in the new company.</p>
            <b-form-input v-model="newEmail" placeholder="Email" type="email" />
            <button @click="sendChangeCompanyRequest" v-if="companyUrl">
              CONFIRM
            </button>
          </div>
          <h6>COMPANY NOT IN THE LIST ?</h6>
          <p>No worries! You can register a new company here.</p>
          <button @click="openRegisterCompanyModal">
            REGISTER A NEW COMPANY
          </button>
        </div>
      </b-container>
    </b-modal>

    <b-modal v-model="isSentCompanyChangeRequest" hide-footer size="md">
      <b-container class="p-4">
        <b-row>
          <div class="register-modal-title mb-3">
            <p class="registerCompanyTitle">CONGRATULATIONS!</p>
            <p>
              The request for changing your company has been successfully sent.
            </p>
          </div>
          <div class="register-modal-bodyText mb-2">
            <p class="registerCompanyTitle">WHAT’S NEXT?</p>
            <p>
              Our team will evaluate your request and you will receive soon a
              notification email containing further informations regarding your
              request. Your profile will be deactivated until the admin confirms
              your request.
            </p>
            <p>Saty tuned!</p>
          </div>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal v-model="isRegisterCompanyModal" hide-footer size="md">
      <b-container class="pb-3">
        <div class="register-modal-title">
          <p class="registerCompanyTitle">NEW COMPANY REGISTRATION</p>
          <p>
            Please fill in the form to send a registration request to the
            Carrier Community Team.
          </p>
        </div>
        <div class="registerCompanyModal">
          <b-row class="mt-sm-3">
            <b-col>
              <b-input
                v-model="newCompanyData.name"
                v-validate="{ required: true }"
                id="inline-form-input-company-name"
                class="register-input"
                placeholder="company name"
                name="company name"
                :class="{ 'is-danger': errors.has('company name') }"
              />
              <small
                v-if="errors.has('company name')"
                class="field-text is-danger"
              >
                {{ errors.first("company name") }}
              </small>

              <b-input
                v-model="newCompanyData.job_title"
                v-validate="{ required: true }"
                id="inline-form-input-job-title_1"
                class="register-input mt-sm-3"
                placeholder="your job title"
                name="job title"
                :class="{ 'is-danger': errors.has('job title') }"
              />
              <small
                v-if="errors.has('job title')"
                class="field-text is-danger"
              >
                {{ errors.first("job title") }}
              </small>

              <b-input
                v-validate="'required|email'"
                name="email"
                v-model="newCompanyData.email"
                id="inline-form-input-email"
                autocorrect="off"
                autocapitalize="off"
                autocomplete="off"
                class="register-input mt-sm-3"
                placeholder="your new email address"
                :class="{ 'is-danger': errors.has('email') }"
              />
              <small v-if="errors.has('email')" class="field-text is-danger">
                {{ errors.first("email") }}
              </small>

              <div class="mt-3">
                <multiselect
                  ref="multiselect"
                  v-validate
                  data-vv-rules="required"
                  data-vv-name="tag"
                  data-vv-value-path="tag"
                  v-model="registerCompanyAddTags"
                  class="tagsMultiselect"
                  :options="tags"
                  track-by="id"
                  label="name"
                  :searchable="false"
                  :close-on-select="false"
                  :show-labels="true"
                  :multiple="true"
                  :maxHeight="150"
                  :clear-on-select="false"
                  open-direction="bottom"
                  placeholder="select one or more industry segments"
                  :allow-empty="true"
                  @open="fetchTags"
                >
                </multiselect>
                <span class="field-text is-danger" v-if="errors.has('tag')">
                  {{ errors.first("tag") }}
                </span>
              </div>

              <b-input
                v-model="newCompanyData.website"
                v-validate="{
                  required: true,
                  url: {
                    require_protocol: true,
                    require_valid_protocol: true,
                    require_tld: false,
                  },
                }"
                id="website"
                class="register-input mt-3"
                placeholder="company website"
                name="website"
                :class="{ 'is-danger': errors.has('website') }"
              ></b-input>
              <small v-if="errors.has('website')" class="field-text is-danger">
                {{
                  errors.first("website") &&
                  errors.first("website") === "The website field is required."
                    ? errors.first("website")
                    : "Please enter a valid website (ex: https://cc.com)"
                }}
              </small>
              <div class="upload-photo">
                <UploadPhoto v-model="newCompanyData.logo" :isCompany="true" />
              </div>
              <div class="sign-up-company-category-col mt-3">
                <p class="sign-up-company-category-text">Your company is a</p>
                <b-form-radio-group
                  v-model="newCompanyData.category"
                  :options="categoryOptions"
                  class="sign-up-company-category-text mb-3 ml-3"
                  value-field="value"
                  text-field="name"
                  plain
                />
              </div>

              <p class="accept-text sign-up-company-category-text mb-4">
                 Clicking on the below button you accept Carrier Community’s
                <router-link :to="{ name: 'TermsAndConditions' }">
                  Terms and Conditions, </router-link
                >
                <router-link :to="{ name: 'PrivacyPolicy' }">
                  Privacy Policy
                </router-link>
                and
                <router-link :to="{ name: 'ConnectPolicy' }"
                  >CC-Connect Policy</router-link
                >.
              </p>

              <b-button
                :class="{
                  companyRegisterButtonDisabled: isDisabled || !isValideEmail,
                  companyRegisterButton: !isDisabled && isValideEmail,
                }"
                block
                @click="registerNewCompany"
                :disabled="isDisabled || !isValideEmail"
                >SEND REQUEST
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <b-modal v-model="isRegisterCompanySuccess" hide-footer size="md">
      <b-container class="pb-3 pl-4 pr-4">
        <b-row>
          <div class="register-modal-title mb-3">
            <p class="registerCompanyTitle">CONGRATULATIONS!</p>
            <p>
              The registration request for your new Company has been
              successfully sent.
            </p>
          </div>
          <div class="register-modal-bodyText mb-2">
            <p class="registerCompanyTitle">WHAT’S NEXT?</p>
            <p>
              Our team will evaluate your request and you will receive soon a
              notification email containing further informations regarding your
              request. Your profile will be deactivated until the admin confirms
              your request.
            </p>
            <p>Saty tuned!</p>
          </div>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal ref="confirm-save-modal" title="Save Settings" hide-footer>
      <b-container fluid class="p-0">
        <b-row
          style="
            border-top: 4px solid #ebebeb;
            border-bottom: 4px solid #ebebeb;
          "
        >
          <p class="warning-text px-2">Are you sure you want to save your settings?</p>
        </b-row>
        <b-row class="justify-content-end d-flex" style="gap: 5px">
          <b-button @click="saveInfo" size="sm" class="ok-btn">YES</b-button>
          <b-button
            @click="$refs['confirm-save-modal'].hide()"
            size="sm"
            class="cancel-btn"
            >CANCEL</b-button
          >
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import VueTelInput from 'vue-tel-input';
import UploadPhoto from './UploadImg.vue';
import ImageCropper from './ImageCropper.vue';

export default {
  components: {
    UploadPhoto,
    ImageCropper,
    VueTelInput,
    Multiselect,
  },
  name: 'UserSettings',
  data() {
    return {
      showCropper: false,
      allCompanies: [],
      companies: [],
      isSelectedCompany: false,
      error: null,
      isValidPhone: false,
      isSuccessSignUp: false,
      isChangeCompanyModal: false,
      isSentCompanyChangeRequest: false,
      isRegisterCompanyModal: false,
      isRegisterCompanySuccess: false,
      isDisabled: true,
      isValideEmail: false,
      filteredCompanies: '',
      searchCompany: '',
      companyUrl: '',
      companyName: '',
      form: {
        company: '',
        tags_to_add: [],
        job_title: '',
        phone_number: '',
        biography: '',
        user: {
          first_name: '',
          last_name: '',
        },
      },
      newCompanyData: {
        email: '',
        job_title: '',
        name: '',
        website: '',
        tags: [],
        category: 'service_provider',
        status: 'active',
        logo: '',
      },
      defaultLogo: '../../../assets/default-user-profile-image.png',
      nameRegex: /[-'\p{L}]+/,
      companyImg: '',
      email: '',
      newEmail: null,
      invalidCharacters: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '±', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '>', '<', '?', '/', '|', '"', '}', '{', '[', ']', '.', ','],
      newJobTitle: null,
      profilePicture: '',
      addTags: [],
      registerCompanyAddTags: [],
      categoryOptions: [
        { value: 'service_provider', name: 'Service Provider' },
        { value: 'vendor', name: 'Vendor' },
      ],
    };
  },
  watch: {
    isSentCompanyChangeRequest: {
      handler() {
        const logOUT = this.logOut;
        setTimeout(() => logOUT(), 4000);
      },
    },
    userInfo: {
      immediate: true,
      async handler(info) {
        const { form } = this;
        const response = await axios(info.company);
        if (response) {
          this.companyImg = response.data.logo;
        }
        this.companyName = info.company_name;
        form.user.first_name = info.first_name;
        form.user.last_name = info.last_name;
        form.job_title = info.job_title;
        form.company = info.comany;
        form.phone_number = info.phone_number;
        form.biography = info.biography;
        this.addTags = info.tags;
        this.email = info.email;
        this.profilePicture = info.profile_picture;
      },
      deep: true,
    },
    searchCompany: {
      async handler(searchValue) {
        if (!searchValue) return;
        this.companies = await this.searchOnCompaniesList(searchValue);
      },
    },
    newCompanyData: {
      handler(val) {
        this.registerButtonDisabled(val);
      },
      deep: true,
    },
    'newCompanyData.email': {
      async handler(email) {
        this.isValideEmail = await this.validateEmail(email);
      },
    },
  },
  computed: {
    ...mapGetters({
      tags: 'tags/fetchItems',
      userInfo: 'user/fetchUserInfo',
    }),
  },
  methods: {
    ...mapActions({
      searchOnCompaniesList: 'companies/searchOnCompaniesList',
      fetchTags: 'tags/fetchList',
      editUserInfo: 'user/editUserInfo',
      registerCompany: 'companies/registerCompany',
      changeCompany: 'companies/changeCompany',
      logOut: 'auth/userLogout',
    }),
    openCropper() {
      this.showCropper = true;
    },
    cancelEditing() {
      this.showCropper = false;
    },
    openChangeCompanyModal() {
      this.isChangeCompanyModal = true;
      this.newEmail = '';
      this.newJobTitle = '';
    },
    openRegisterCompanyModal() {
      this.isRegisterCompanyModal = true;
    },
    registerButtonDisabled(newCompanyData) {
      if (
        newCompanyData.email
        && newCompanyData.job_title
        && newCompanyData.name
        && newCompanyData.website
        && this.registerCompanyAddTags.length
      ) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    setCompanyUrl(company) {
      this.companyUrl = company.url;
      this.isSelectedCompany = company.id;
    },
    async sendChangeCompanyRequest() {
      this.isChangeCompanyModal = false;

      this.changeCompany({
        company: this.companyUrl,
        user_details: {
          job_title: this.newJobTitle || '',
          email: this.newEmail || '',
        },
      });
      this.isSentCompanyChangeRequest = true;
    },
    onInput({ isValid }) {
      this.isValidPhone = !isValid;
    },
    validateEmail(email) {
      return this.$validator.validate('email', email);
    },

    async savePhoto(img) {
      if (img) {
        await this.editUserInfo({ profile_picture: img }).then(() => {
          this.$notify({
            group: 'userAlerts',
            title: 'Success',
            text: 'The photo successfully saved!',
            type: 'success',
          });
        });
      }
      this.showCropper = false;
    },
    async showConfirmSaveModal() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        return this.$notify({
          group: 'userAlerts',
          title: 'Error',
          text: 'Please fill in all required fields!',
          type: 'error',
        });
      }
      this.$refs['confirm-save-modal'].show();
    },
    async saveInfo() {
      if (this.addTags.length) {
        this.form.tags_to_add = this.addTags.map(tag => tag.url);
      }
      if (this.profilePicture !== this.userInfo.profile_picture) {
        this.form.profile_picture = this.profilePicture;
      }
      if (this.email !== this.userInfo.email) {
        this.form.user.email = this.email;
        this.form.user.username = this.email;
      }
      await this.editUserInfo(this.form);
      this.$notify({
        group: 'userAlerts',
        title: 'Success',
        text: 'Your settings have been saved!',
        type: 'success',
      });
      this.$router.push({ name: 'userProfilePage' });
    },
    async registerNewCompany() {
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        const {
          job_title, email, name, domain, website, category,
        } = this.newCompanyData;
        const newCompany = {
          company: '',
          user_details: {
            job_title: job_title || '',
            email: email || '',
          },
          create_company_details: {
            status: 'on_hold',
            name,
            domain,
            website,
            category,
          },
        };

        if (this.newCompanyData.logo) {
          newCompany.create_company_details.logo = this.newCompanyData.logo;
        }
        if (this.registerCompanyAddTags.length) {
          newCompany.create_company_details.tags = this.registerCompanyAddTags.map(tag => tag.id);
        }
        try {
          this.changeCompany(newCompany);
          this.isSentCompanyChangeRequest = true;
          this.$notify({
            group: 'userAlerts',
            title: 'Success',
            text: 'Your request sent!',
            type: 'success',
          });
          this.isRegisterCompanySuccess = true;
        } catch (error) {
          this.$notify({
            group: 'userAlerts',
            title: 'Error',
            text: error,
            type: 'error',
          });
        }
      }
    },
  },
};
</script>
<style src="./Settings.css" scoped></style>
<style>
.ok-btn {
  background-color: #bd9d5f !important;
  color: #fff !important;
}
.tagsMultiselect .multiselect__tag {
  padding: 3px 5px;
  border-radius: 8px;
  margin-right: 4px;
  background-color: #aaaaaa;
  font-size: 10px;
}
.tagsMultiselect .multiselect__tag-icon {
  display: none;
}
</style>
<style scoped>
.user-settings-body {
  background-color: white;
}
.user-settings-main-text,
.user-setting-button,
.register-modal-title {
  line-height: 1.13;
}
.user-settings-header,
.your-profile,
.user-settings-main-text,
.user-setting-button,
.changeCompanyModal p,
.changeCompanyModal h6,
.register-modal-title,
.register-modal-bodyText,
.companyRegisterButtonDisabled,
.companyRegisterButton {
  text-align: center;
}
.user-settings-header {
  padding: 15px;
  background-color: white;
  margin-bottom: 15px;
  border-bottom: 2px solid #ebebeb;
}
.your-profile {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 0.83;
  color: #333333;
}
.user-settings-main-text {
  font-size: 15px;
  color: #666666;
}
.user-setting-button {
  padding: 10px;
  font-family: Arial;
  font-size: 15px;
  color: #ffffff;
  background: #bd9d5f !important;
  border: unset !important;
}
.inputs-label {
  font-family: Arial;
  font-size: 11px;
  line-height: 0;
  text-align: left;
  color: #666666;
}
.user-setting-input {
  height: 30px;
  border-radius: 2px;
  border: solid 1px #aaaaaa;
  font-family: Arial;
  font-size: 12px;
}
#Personal {
  color: #4d4d4d;
}
span.error {
  color: #9f3a38;
  font-size: 13px;
}
.companyInfoBox {
  padding: 13px 16px;
  margin-top: 25px;
}
.companyImgBox {
  display: grid;
  grid-template-columns: 110px 1fr;
}
.companyImgBox img {
  width: 100px;
  height: 100px;
}
.companyImgBox button {
  background-color: #fff;
  color: #bd9d5f;
  border: 1px solid #bd9d5f;
  width: 100%;
  padding: 10px;
}
.companyImgBox button:hover {
  color: #fff;
  background-color: #bd9d5f;
}
.companyImgBox p,
.changeCompanyModal p {
  color: #666666;
  font-size: 12.5px;
}
.changeCompanyModal button {
  width: 100%;
  background-color: #bd9d5f;
  color: #fff;
  border-radius: 5px;
  margin-top: 10px;
  border: 1px solid #bd9d5f;
  font-size: 12px;
  padding: 5px 0px;
}
.changeCompanyModal button:hover {
  background-color: #fff;
  color: #bd9d5f;
}
.changeCompanyModal h6 {
  margin-top: 15px;
}
.changeCompanyModal {
  padding: 12px 4px;
}
section {
  margin: 20px 0px;
  height: 15vh;
  overflow: auto;
}
.companies:hover {
  background-color: #ecc984;
}
.active {
  background-color: #e4ae41 !important;
}
.companies div {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
}
.companies img {
  width: 20%;
  padding-right: 10px;
}
.register-modal-title {
  margin: -2.6rem 0 2rem 0;
}
.registerCompanyTitle {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  color: #333333;
}
.register-modal-bodyText {
  color: #333333;
}
.closeAllModalsButton {
  width: 100%;
  background-color: #bd9d5f;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  line-height: 2;
}
.field-text {
  font-family: Arial;
  font-size: 12px;
  text-align: left;
  color: #ff0000;
  transition: 0.5s;
}
.sign-up-company-category-col {
  display: flex;
}
.sign-up-company-category-text {
  font-size: 14px;
  color: #666666;
}
.companyRegisterButton {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border: solid 1px #bd9d5f;
  background-color: #bd9d5f !important;
  color: #ffffff;
  font-size: 12px;
}
.companyRegisterButtonDisabled {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  border: solid 1px #aaaaaa;
  background-color: #ebebeb !important;
  color: #aaaaaa !important;
  font-size: 12px;
}
@media (max-width: 768px) {
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .userSettingsForm > div {
    margin-right: 0;
    margin-left: 0;
  }
  .userSettingsForm .profilePictureSection {
    padding: 0 !important;
    margin-bottom: 0.25rem !important;
    border-radius: 0 10px 0 0;
    overflow: hidden;
  }
  .user-settings-body {
    border-radius: 0 10px 10px 10px;
  }
  .user-setting-button,
  .companyImgBox button {
    border-radius: 0 10px 10px 10px;
  }
  .profilePicture {
    width: 100%;
    display: flex;
  }
  .profilePicture > img {
    width: 200px;
    flex-basis: 752px;
  }
}
</style>
